import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable, ReplaySubject, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SbrModulosService {
  
  private api = environment.api;

  private _sbrmodulos: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);

  set sbrmodulos$(s: any) { this._sbrmodulos.next(s); }
  get sbrmodulos$(): Observable<any[]> { return this._sbrmodulos.asObservable(); }

  constructor(private _httpClient: HttpClient)
  {}

  redirectAuthenticate(modulo: string)
  {
    return this._httpClient.get(this.api + `/auth/redirect-module`, {params: {app_modulo: modulo}}).pipe(
      map((res) => res),
      catchError((err: HttpErrorResponse) => {
        return throwError(err);
      })
    );
  }

}
