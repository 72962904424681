/* eslint-disable @typescript-eslint/naming-convention */
export const environment = {
    production: false,
    ambientserver: 'development',
    version: '4.4.0', // 04/07/2024
    url: 'https://hml.pickingup.com.br',
    api_auth: 'https://auth.cprdigital.com.br/backend/oauth',
    api: 'https://auth.cprdigital.com.br/backend/api',
    api_backend: 'https://hml.pickingup.com.br/backend/api',
    api_mslogger: 'https://hml.pickingup.com.br/mslogger',
    client_id: '951103d1-ea10-4649-809d-90d76f5cf3c7',
    client_secret: 'EFGTadB5075yYtYpNfimPbY8ByOsUKq6pDRSMJiD',
    url_document: 'https://sbrcargo.nyc3.digitaloceanspaces.com/blueprints/',
    url_s3: 'https://sbrcargo.nyc3.digitaloceanspaces.com/',
    // Apis de outros servidores
    api_backend_hml: 'https://hml.pickingup.com.br/backend/api',
    api_backend_prod: 'https://prod.pickingup.com.br/backend/api',
};
